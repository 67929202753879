/* You can add global styles to this file, and also import other style files */

@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import "../node_modules/alertifyjs/build/css/alertify.css";
@import "../node_modules/alertifyjs//build/css/themes/default.min.css";
* {
    font-family: 'Montserrat', sans-serif;
}

body {
    background: #fff;
}

.fz-14 {
    font-size: 14px;
}

.fz-12 {
    font-size: 12px;
}

.min-w-title {
    min-width: 60px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #cccccc24;
    opacity: 1;
}

.teme-gray {
    background: #f9f9f9 !important;
}

input.error,
select.error {
    border-bottom: 1px solid #a94442;
}

input.valid,
select.valid {
    border-bottom: 1px solid #4cae4c;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #a94442;
    font-size: 0.8rem;
    line-height: 1rem;
}

.toast {
    opacity: revert !important;
    color: #fff;
    background-color: rgba(0, 0, 0, .6)!important;
}

.toast-body {
    padding: 5px 16px!important;
}

.toast.botton-left {
    position: fixed;
    z-index: 9999;
    margin-bottom: 1rem;
    bottom: 0;
    margin-left: 1rem;
}

.toast.botton-right {
    position: fixed;
    z-index: 9999;
    margin-bottom: 1rem;
    bottom: 0;
    right: 0;
    margin-right: 1rem;
}

.toast.top-right {
    position: fixed;
    z-index: 9999;
    margin-top: 1rem;
    right: 0;
    margin-right: 1rem;
}

.toast.toast-success {
    background-color: #218838 !important;
}

.toast.toast-danger {
    background-color: #444 !important;
}

.capa-menu {
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 50;
    cursor: pointer;
}

.icon-menu-mbs {
    color: #1111119c !important;
    font-size: 21px;
}

.icon-menu-mbs.active {
    color: #01abce !important;
}

.fdc {
    margin-top: -8px;
    color: #777;
}


/* offcanvas  */

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-zindex: 9999;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 90%;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: ;
    --bs-offcanvas-bg: #fff;
    --bs-offcanvas-border-width: 1px;
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.offcanvas-body {
    height: 100% !important;
    padding: 1rem;
    padding-bottom: 20px;
    overflow-y: auto;
}

.offcanvas-body>div {
    max-height: 100%;
    padding-bottom: 10px;
}

.bg-RV {
    color: #fff;
    background-color: #007bff !important;
}

.bg-RF {
    color: #444;
    background-color: #007bff1a !important;
}

.bg-CT {
    color: #444;
    background-color: #cccccc24 !important;
}

.fa-icon-filter {
    font-size: 22px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .offcanvas.offcanvas-end,
    .offcanvas.offcanvas-start {
        bottom: 50px;
    }
}

.img-base {
    background: #0d6efd2e;
    border-radius: 5px;
    padding: 10px;
    object-fit: cover;
}

@media (max-width: 768px) {
    .fb {
        bottom: 80px;
    }
}

.range-color {
    font-size: 20px;
}

.mbsc-ios.mbsc-ltr.mbsc-stepper-cont {
    padding: 0 !important;
}

.mbsc-ios.mbsc-ltr .mbsc-stepper {
    right: 0 !important;
}

.bg-white {
    background-color: white;
}

.mbsc-stepper .mbsc-stepper-val,
.mbsc-stepper .mbsc-comp.mbsc-control {
    width: 100px !important;
}

.mbsc-ios.mbsc-form {
    background: white !important;
}

.mbsc-input {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    margin-top: 5px !important;
}

.mbsc-ios.mbsc-control-w:before,
.mbsc-ios.mbsc-control-w:after {
    border: 0px !important;
}

.myIframe {
    overflow: none;
    width: 100%;
    height: 100%;
}

.alertify-notifier {
    z-index: 999999 !important;
}

.webcam-wrapper {
    background: #000000e0;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.webcam-wrapper video {
    width: 400px;
    height: 300px;
    object-fit: scale-down;
    background: #000000e0;
    border: 2px solid #00b2cd;
    border-radius: 7px;
}

@media (max-width: 768px) {
    .webcam-wrapper {
        background: #000000e0;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        width: 70%;
    }
    .webcam-wrapper video {
        width: 100%;
        height: 100%;
        min-height: 200px;
    }
}

.mbsc-ms-item {
    padding: 0.20em 0.5em !important;
}

.alert-success {
    background-color: #d1e7dd3b !important;
}

.alert-primary {
    background-color: #cfe2ff3b !important;
}

.alert-secondary {
    background-color: #e2e3e53b !important;
}

.mbsc-ios-dark .mbsc-fr-btn {
    color: #007bff !important;
}

.mbsc-ios.mbsc-ms-c {
    background: #f7f7f7f2 !important;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.orange {
    color: orange;
}

.card.cmt2 {
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.20);
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.20);
    border-radius: 12px;
    border: 0;
    background: #fff;
    border: 1px solid #cccccc8c;
    cursor: pointer;
}

.card.cmt2:hover {
    background: #fafafa;
}

.light {
    color: #80808052;
}

.orange {
    color: orange;
}